const mailchimpForm=document.getElementById('mailchimp-form');
if (mailchimpForm) {
  mailchimpForm.addEventListener('submit',e=>{
    e.stopPropagation();
    let url=mailchimpForm.dataset.url;
    if (url){
      e.preventDefault();
      let data = new FormData(mailchimpForm);
      fetch(url, {
        method: 'POST',
        body: data,
        dataType: 'html',
        encode: false
      })
        .then(c=>c.text())
        .then(
          c=>{
            document.getElementById('mailchimp-ajax-response').innerHTML=c;
            return c;
          }
        );
    }
  })
};
